
export default {
    data() {
        return {
            rules: {
                title: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                username: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                companyName: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                contactName: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: this.$t('share.require'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            form: {},
            // 提交loading
            loading: false
        }
    },
    methods: {
        submit() {
            this.loading = true;
            this.$http.post("/installer/install", this.form).then(res => {
                if (res.data.code === 0) {
                    this.$router.push("/");
                    this.$message.success(res.data.msg)
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch((e) => {
                this.$message.error(e)
            }).finally(() => {
                this.loading = false;
            })
        },
        /**
         * 获取系统注册
         */
        getStatus() {
            this.$http.get("/installer/status").then(res => {
                if (res.data.code == 0) {
                    this.$router.push("/");
                    this.$message.success(res.data.msg)
                }
            })
        }
    },
    mounted(){
        this.getStatus();
    }
}
